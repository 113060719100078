import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Navbar from '../../Navigation/Navbar';
import Footer from "../../Footer/Footer"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm('service_qbzazin', 'template_ej7pt6f', event.target, 'rrrqvFP21hvju_Uon')
      .then((result) => {
        console.log(result.text);
        alert('Message Sent Successfully!');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((error) => console.error(error));
  };

  return (

    <div>
      <Navbar/>
      <br/><br/><br/>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="lg:col-span-1 flex flex-col justify-center px-6 py-8">
          <h1 className="text-4xl font-bold mb-4">Get In Touch</h1>
          <p className="text-gray-700 mb-8">
          Sylco Tower (3rd Floor, Lift-3) Jail Road Point, Sylhet - 3100, Bangladesh
          </p>
          <div className="flex flex-col space-y-2">
            <p className="text-gray-700">Email: educationcareitsolution@gmail.com</p>
            <p className="text-gray-700">Phone: +880 1971-634411</p>
          </div>
        </div>
        <div className="lg:col-span-1 flex flex-col justify-center px-6 py-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2 font-bold" htmlFor="name">
                Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 mb-2 font-bold"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Your Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 mb-2 font-bold"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                type="text"
                placeholder="Write your message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
export default Contact
