import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Navbar from '../../Navigation/Navbar';
import Footer from "../../Footer/Footer"

const AppointmentPage = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      fullName,
      email,
      phoneNumber,
      businessName,
      selectedDate,
      selectedTime,
      selectedService,
      message
    };

    emailjs.send('service_qbzazin', 'template_zj9dd0d', templateParams, 'rrrqvFP21hvju_Uon')
      .then(() => {
        setSuccessMessage('Appointment request sent successfully!');
        setFullName('');
        setEmail('');
        setPhoneNumber('');
        setBusinessName('');
        setSelectedDate('');
        setSelectedTime('');
        setSelectedService('');
        setMessage('');
      })
      .catch((error) => console.error('Error sending email:', error));
  };

  return (
    <div>
      <Navbar />
      <br/><br/><br/>
      <div className="max-w-lg mx-auto p-8 border border-black rounded-lg">
        <h1 className="text-2xl font-bold mb-4">Book an Appointment</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="fullName" className="block">Full Name:</label>
            <input type="text" id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block">Email:</label>
            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block">Phone Number:</label>
            <input type="tel" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <div className="mb-4">
            <label htmlFor="businessName" className="block">Your Business Name:</label>
            <input type="text" id="businessName" value={businessName} onChange={(e) => setBusinessName(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <div className="mb-4">
            <label htmlFor="date" className="block">Choose Date:</label>
            <input type="date" id="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <div className="mb-4">
            <label htmlFor="time" className="block">Choose Time:</label>
            <input type="time" id="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <div className="mb-4">
            <label htmlFor="service" className="block">Select Service:</label>
            <select id="service" value={selectedService} onChange={(e) => setSelectedService(e.target.value)} className="border border-black rounded p-2 w-full">
              <option value="">Select...</option>
              <option value="Website Development">Website Development</option>
              <option value="WordPress Development">WordPress Development</option>
              <option value="Graphics Design">Graphics Design</option>
              <option value="SEO">SEO</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Mobile App">Mobile App</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block">Message:</label>
            <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} className="border border-black rounded p-2 w-full" />
          </div>
          <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 w-full">Get Appointment</button>
        </form>
        {successMessage && <p className="mt-4 text-green-600">{successMessage}</p>}
      </div>
      <br/><br/>
      <Footer />
    </div>

  );
};

export default AppointmentPage;
