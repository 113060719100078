import React from 'react'
import "./service.css"
import { IoIosCall } from "react-icons/io";
import { GrSecure } from "react-icons/gr";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";

function Service() {
  return (

    <section class="section service" aria-labelledby="service-label">
        <div class="container">

          <p class="section-subtitle" id="service-label">What We Do?</p>

          <h2 class="h2 section-title">
            The service we offer is specifically designed to meet your needs.
          </h2>

          <ul class="grid-list">

            <li>
              <div class="service-card">

                <div class="card-icon">
                  <IoIosCall></IoIosCall>
                </div>

                <h3 class="h4 card-title">24/7 Support</h3>

                <p class="card-text">
                We provide 24/7 support to ensure continuous assistance for our customers.
                </p>

                <a href="#" class="btn-text">

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

            <li>
              <div class="service-card">

                <div class="card-icon">
                  <GrSecure></GrSecure>
                </div>

                <h3 class="h4 card-title">Secure Payments</h3>

                <p class="card-text">
                Secure payments ensure the protection of sensitive financial information during transactions.
                </p>

                <a href="#" class="btn-text">

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

            <li>
              <div class="service-card">

                <div class="card-icon">
                <IoCloudDownloadOutline></IoCloudDownloadOutline>
                  
                </div>

                <h3 class="h4 card-title">Daily Updates</h3>

                <p class="card-text">
                Stay informed with our daily updates, delivering concise summaries of important news and events.
                </p>

                <a href="#" class="btn-text">

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

            <li>
              <div class="service-card">

                <div class="card-icon">
                  <IoIosTimer></IoIosTimer>
                </div>

                <h3 class="h4 card-title">Market Research</h3>

                <p class="card-text">
                Market research is pivotal for understanding consumer behavior, guiding strategic decisions, and achieving business success.
                </p>

                <a href="#" class="btn-text">

                  <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </a>

              </div>
            </li>

          </ul>

        </div>
      </section>
  )
}

export default Service
