import React from 'react';
import Navbar from '../../Navigation/Navbar';
import { SiDigitalocean } from 'react-icons/si';
import { DiWebplatform } from "react-icons/di";
import { FaWordpress } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { SiTaichigraphics } from "react-icons/si";
import { TbSeo } from "react-icons/tb";
import Footer from "../../Footer/Footer"

function Services() {
  return (
    <div>
      <Navbar />
      <section className="section service" id="service" aria-label="service">
        <div className="container">
          <h2 className="text-3xl font-bold text-center mb-6">Services We Provide</h2>
          <p className="text-lg text-center mb-8">
          We offer comprehensive web solutions encompassing web development, mobile app development, and WordPress development, ensuring tailored digital experiences for every client. Additionally, our expertise in graphics design, SEO, and digital marketing empowers businesses to enhance their online presence and reach their target audience effectively.
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <li>
              <div className="service-card text-black text-black rounded-lg p-8 text-center transition-transform hover:-translate-y-2">
                <div className="card-icon bg-green-500 mx-auto rounded-full p-6 mb-4">
                  <DiWebplatform className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2"><a href="#" className="card-title">Web Development</a></h3>
                <p className="text-lg">We create responsive and dynamic websites tailored to your needs for your business.</p>
              </div>
            </li>
            <li>
              <div className="service-card text-black text-black rounded-lg p-8 text-center transition-transform hover:-translate-y-2">
                <div className="card-icon bg-green-500 mx-auto rounded-full p-6 mb-4">
                  <FaWordpress className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2"><a href="#" className="card-title">WordPress Development</a></h3>
                <p className="text-lg">Get a feature-rich and user-friendly WordPress website for your business.</p>
              </div>
            </li>
            <li>
              <div className="service-card text-black text-black rounded-lg p-8 text-center transition-transform hover:-translate-y-2">
                <div className="card-icon bg-green-500 mx-auto rounded-full p-6 mb-4">
                  <FaMobileAlt className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2"><a href="#" className="card-title">Mobile App Development</a></h3>
                <p className="text-lg">Build custom mobile applications for iOS and Android platforms for your business.</p>
              </div>
            </li>
            <li>
              <div className="service-card text-black text-black rounded-lg p-8 text-center transition-transform hover:-translate-y-2">
                <div className="card-icon bg-green-500 mx-auto rounded-full p-6 mb-4">
                  <SiTaichigraphics className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2"><a href="#" className="card-title">Graphics Design</a></h3>
                <p className="text-lg">Our talented designers will bring your ideas to life with stunning graphics.</p>
              </div>
            </li>
            <li>
              <div className="service-card text-black text-black rounded-lg p-8 text-center transition-transform hover:-translate-y-2">
                <div className="card-icon bg-green-500 mx-auto rounded-full p-6 mb-4">
                  <TbSeo className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2"><a href="#" className="card-title">Search Engine Optimization</a></h3>
                <p className="text-lg">Optimize your website to rank higher in search engine results and attract more visitors.</p>
              </div>
            </li>
            <li>
              <div className="service-card text-black text-black rounded-lg p-8 text-center transition-transform hover:-translate-y-2">
                <div className="card-icon bg-green-500 mx-auto rounded-full p-6 mb-4">
                  <SiDigitalocean className="text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-2"><a href="#" className="card-title">Digital Marketing Service</a></h3>
                <p className="text-lg">Promote your brand and reach your target audience through various digital channels.</p>
              </div>
            </li>
            {/* Repeat the pattern for other services */}
          </ul>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default Services;
