import React from "react";
import Navbar from "../../Navigation/Navbar";
import Herosection from "../../Herosection/Herosection";
import Features from "../../../Features/Features";
import Service from "../../../Service/Service";
import Policy from "../../Policy/Policy"
import Footer from "../../Footer/Footer"



const Homepage = () => {


  return (
    <>
      <Navbar/>
      <Herosection/>
      <Features/>
      <Service/>
      <Policy/>
      <Footer/>
    </>
  );
};

export default Homepage;
