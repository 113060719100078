import React from 'react'
import "./Csss.css"
import { FaFacebookSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div>
            <section class="cta" aria-label="call to action">
                <div class="container">

                    <h2 class="h2 section-title">
                        Join our community by using our services and grow your business.
                    </h2>

                    <Link class="btn btn-primary" to="/contact">Contact Us</Link>


                </div>
            </section>
            <footer class="footer">
                <div class="container grid-list">

                    <div class="footer-brand">

                        <a href="#" class="logo">
                            <h2>An Tech Innovations</h2>
                        </a>

                        <p class="footer-text">
                            &copy; 2024 An Tech Innovations. <br /> All rights reserved.
                        </p>

                        <ul class="social-list">

                            <li>
                                <a href="#" class="social-link">
                                    <FaFacebookSquare></FaFacebookSquare>
                                </a>
                            </li>

                            <li>
                                <a href="#" class="social-link">
                                    <FaXTwitter></FaXTwitter>
                                </a>
                            </li>

                            <li>
                                <a href="#" class="social-link">
                                    <FaGithub></FaGithub>
                                </a>
                            </li>

                            <li>
                                <a href="#" class="social-link">
                                    <FaInstagramSquare></FaInstagramSquare>
                                </a>
                            </li>

                            <li>
                                <a href="#" class="social-link">
                                    <FaYoutube></FaYoutube>
                                </a>
                            </li>

                        </ul>

                    </div>

                    <ul class="footer-list">

                        <li>
                            <p class="h4 footer-list-title">Get in Touch</p>
                        </li>

                        <li>
                            <address class="footer-text">
                                Sylco Tower (3rd Floor, Lift-3) Jail Road Point, Sylhet - 3100, Bangladesh
                            </address>
                        </li>

                        <li>
                            <a href="mailto:educationcareitsolution@gmail.com" class="footer-link">educationcareitsolution@gmail.com</a>
                        </li>

                        <li>
                            <a href="tel:880 1753-111777" class="footer-link">880 1753-111777</a>
                            <a href="tel:8801925111444" class="footer-link">8801925111444</a>
                        </li>

                    </ul>

                    <ul class="footer-list">

                        <li>
                            <p class="h4 footer-list-title">Learn More</p>
                        </li>
                        
                        <li>
                            <Link to="/">Home</Link>
                        </li>

                        <li>
                            <Link to="/about">About</Link>
                        </li>

                        <li>
                            <Link to="/services">Services</Link>
                        </li>

                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>

                    </ul>

                    <div class="footer-list">

                        <p class="h4 footer-list-title">Our Newsletter</p>

                        <p class="footer-text">
                            Subscribe to our newsletter to get our news & deals delivered to you.
                        </p>


                    </div>

                </div>
            </footer>
        </div>

    )
}

export default Footer
