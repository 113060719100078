import React, { useEffect, useRef } from "react";
import Navbar from "../../Navigation/Navbar";
import { useAnimation, useInView, motion } from "framer-motion";
import TeamImage from '../../../images/Team/team1.jpg'
import Footer from "../../Footer/Footer"
import logo1 from "../../../images/Features/f1.png";
import logo2 from "../../../images/Features/f2.png";
import logo3 from "../../../images/Features/f3.png";
import logo4 from "../../../images/Features/f4.png";
import logo5 from "../../../images/Features/f5.png";
import logo6 from "../../../images/Features/f6.png";
import image1 from "../../../images/Features/fb11.jpg";
import image2 from "../../../images/Features/fb22.jpg";

const About = () => {
  const { innerWidth: Width } = window;
  const ref = useRef();
  const isInView = useInView(ref);
  const mainControlls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControlls.start("visible");
    }
  }, [isInView]);
  return (
    <div>
      <Navbar />
      <br /><br />
      <div className="about-us bg-gray-100 py-16">
        <div className="container mx-auto px-4 md:flex md:flex-row md:space-x-12 items-center mb-16">
          <div className="w-full md:w-1/2 order-2 md:order-1">
            <img
              className="rounded-lg shadow-md object-cover h-full md:h-auto"
              src={image1}
              alt="Group"
            />
          </div>
          <div className="flex-1 px-4 md:px-0 order-1 md:order-2">
            <h1 className="text-3xl font-bold mb-4 text-primary">About Us</h1>
            <p className="text-lg leading-loose mb-4">
              Education Care IT Solution is a pioneering IT agency dedicated to revolutionizing educational technology. As a newly established company, we are driven by a passion for leveraging cutting-edge digital solutions to enhance the learning experience for students, educators, and institutions alike. With a focus on innovation, creativity, and customer-centricity, we strive to address the evolving needs of the education sector in today's rapidly changing technological landscape.
              <br />
              At Education Care IT Solution, we offer a comprehensive suite of services tailored to meet the unique requirements of educational institutions at all levels. From developing interactive e-learning platforms and customized educational apps to implementing robust school management systems and providing IT consultancy services, our expert team is committed to delivering solutions that empower educators and learners, streamline administrative processes, and foster a culture of continuous improvement within educational organizations.
              <br />
              Our mission at Education Care IT Solution is to bridge the gap between traditional teaching methods and modern technological advancements, thereby fostering a dynamic and engaging learning environment that nurtures the growth and development of students worldwide. With a relentless dedication to excellence, integrity, and innovation, we envision a future where every educational institution has access to the latest IT solutions tailored to their specific needs, enabling them to thrive in the digital age. Join us in shaping the future of education through technology, where learning knows no bounds and possibilities are limitless.
            </p>
          </div>
        </div>

        <h2 className="text-2xl font-bold mb-8 text-center text-primary">Our Mission</h2>
        <p className="text-lg leading-loose text-center mb-12 text-gray-700">
          Our mission at Education Care IT Solution is to empower educational institutions with innovative and tailored IT solutions that enhance the teaching and learning experience. We are committed to bridging the gap between traditional education and the digital era, providing educators and learners with access to cutting-edge technology that fosters collaboration, creativity, and engagement. Through our relentless pursuit of excellence and customer-centric approach, we aim to revolutionize the education sector by delivering comprehensive, reliable, and user-friendly IT solutions that meet the evolving needs of today's learners and educators. At Education Care IT Solution, our mission is to inspire a lifelong love of learning and facilitate the transformation of education through technology, ensuring that every student has the opportunity to reach their full potential in an increasingly interconnected world.
        </p>

        <h2 className="text-2xl font-bold mb-8 text-center text-primary">Our Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-4 border rounded-lg shadow-md flex flex-col items-center">
            <i className="fas fa-lightbulb text-4xl text-primary mb-4"></i>
            <h3 className="text-lg font-bold mb-2">Innovation</h3>
            <p className="text-base leading-loose text-center text-gray-700">
              We embrace new technologies and creative approaches to deliver cutting-edge solutions.
            </p>
          </div>
          <div className="p-4 border rounded-lg shadow-md flex flex-col items-center">
            <i className="fas fa-handshake text-4xl text-primary mb-4"></i>
            <h3 className="text-lg font-bold mb-2">Collaboration</h3>
            <p className="text-base leading-loose text-center text-gray-700">
              We work closely with our clients to understand their needs and build successful partnerships.
            </p>
          </div>
          <div className="p-4 border rounded-lg shadow-md flex flex-col items-center">
            <i className="fas fa-rocket text-4xl text-primary mb-4"></i>
            <h3 className="text-lg font-bold mb-2">Results-Oriented</h3>
            <p className="text-base leading-loose text-center text-gray-700">
              We're passionate about measurable outcomes and delivering projects that drive success for our clients.
            </p>
          </div>
        </div>

        <br />
        <h2 className="text-2xl font-bold mb-8 text-center text-primary">Why Choose Us?</h2>

        <div className="container mx-auto px-4 flex flex-col md:flex-row md:space-x-8 items-center mb-12 justify-center">  <div className="w-full md:w-1/3">
          <img
            className="rounded-lg shadow-md object-cover"
            src={image2}
            alt="Checkmarks representing success"
          />
        </div>
          <div className="flex-1 px-4 md:px-0">
            <p className="text-lg leading-loose text-gray-700">
              Looking for a reliable IT partner who delivers results? Look no further than AN Tech Innovations. We empower businesses like yours with cutting-edge technology solutions designed to drive success. Here's what sets us apart:
            </p>
            <ul className="list-disc space-y-2 mt-4 text-lg leading-loose text-gray-700">
              <li className="flex items-center">
                <span className="mr-2 text-primary">✔</span>
                Experienced and certified IT professionals
              </li>
              <li className="flex items-center">
                <span className="mr-2 text-primary">✔</span>
                Proven track record of successful project delivery
              </li>
              <li className="flex items-center">
                <span className="mr-2 text-primary">✔</span>
                Agile and flexible development methodology
              </li>
              <li className="flex items-center">
                <span className="mr-2 text-primary">✔</span>
                Data-driven approach to ensure measurable results
              </li>
              <li className="flex items-center">
                <span className="mr-2 text-primary">✔</span>
                Excellent communication and client support
              </li>
            </ul>
          </div>
        </div>




        <h2 className="text-2xl font-bold mb-8 text-center text-primary">Contact Information</h2>
        <div className="flex justify-center items-center space-x-4 mb-8">
          <i className="fas fa-envelope text-2xl text-primary"></i>
        </div> 
        <div className="flex justify-center items-center space-x-4">
          <i className="fas fa-phone text-2xl text-primary"></i>
          <p className="text-lg leading-loose text-gray-700 text-justify">
              If you have any questions or inquiries, feel free to reach out to us. Our team is ready to assist you in any way we can.<br/>Email: educationcareitsolution@gmail.com
          </p>
    
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
